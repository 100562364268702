var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-group",
    {
      staticClass: "hours-list",
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function() {
            return [
              _vm.todayHours
                ? _c(
                    "v-list-item-title",
                    {
                      staticClass: "d-flex black--text today-title text-body-3"
                    },
                    [
                      _c("span", { staticClass: "week-day font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.todayHours.weekDay))
                      ]),
                      _c("v-spacer"),
                      _vm.todayHours.hours
                        ? _c("span", {
                            staticClass: "hours",
                            domProps: {
                              innerHTML: _vm._s(_vm.todayHours.hours)
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.otherDayHours && _vm.otherDayHours.length > 0
        ? _vm._l(_vm.otherDayHours, function(day) {
            return _c(
              "v-list-item",
              { key: day.weekDay, staticClass: "opening-day-item" },
              [
                _c(
                  "v-list-item-title",
                  { staticClass: "d-flex opening-day-title text-body-3" },
                  [
                    _c("span", { staticClass: "week-day" }, [
                      _vm._v(_vm._s(day.weekDay))
                    ]),
                    _c("v-spacer"),
                    day.hours
                      ? _c("span", {
                          staticClass: "hours",
                          domProps: { innerHTML: _vm._s(day.hours) }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }