<template>
  <v-container class="warehouse-detail" v-if="warehouse">
    <div class="d-flex align-center justify-space-between mb-3">
      <h4 class="text-uppercase my-3">
        {{ $t("warehouse.activeLeaflet") }}
      </h4>
      <v-btn
        rounded
        text
        color="primary"
        small
        class="nav-btn"
        :to="{ name: 'Leaflet' }"
        exact
        >{{ $t("warehouse.allShops")
        }}<v-icon right x-small>$arrowForward</v-icon></v-btn
      >
    </div>
    <v-btn
      v-if="showSelectStoreBtn"
      :key="'btn-n-' + key"
      block
      x-large
      outlined
      rounded
      :loading="loading"
      color="secondary"
      @click="setStore(warehouse.warehouseId)"
      class="mt-4 mb-3 font-weight-semibold"
      ><img src="/logo/foglia_big_t-red.png" width="30" class="mr-1" />{{
        $t("warehouse.markAsPrefered")
      }}</v-btn
    >
    <v-btn
      v-else
      :key="'btn-s-' + key"
      block
      x-large
      outlined
      rounded
      color="primary"
      class="mt-4 mb-3 font-weight-semibold"
      ><img src="/logo/foglia_big_t_filled.png" width="30" class="mr-1" />{{
        $t("warehouse.selectedShop")
      }}</v-btn
    >
    <v-row class="mt-2"
      ><v-col
        cols="12"
        sm="6"
        v-for="(leaflet, index) in leaflets"
        :key="'leaflet-' + index"
        class="pt-0"
        ><div class="leaflet-info" @click="openDetails(leaflet.leafletId)">
          <div class="img-wraper">
            <img
              :src="leaflet.baseLocation + leaflet.firstPagePreview"
              :alt="leaflet.name"
            />
          </div>
          <div class="info-wraper flex-grow-1">
            <h4 class="my-0">{{ leaflet.name }}</h4>
            <div class="">
              Dal {{ $dayjs(leaflet.fromDate).format("DD MMMM") }} al
              {{ $dayjs(leaflet.toDate).format("DD MMMM") }}
            </div>
            <div v-if="leaflet.leafletStatus == true" style="color:#FFA500 ;">
              <li><b>In arrivo</b></li>
            </div>
            <div v-else-if="leaflet.leafletStatus == false" style="color:green ;">
              <li><b>In corso</b></li>
            </div>
          </div>
        </div></v-col
      ></v-row
    >
    <div class="warehouse-detail-column mt-4">
      <h4 class="primary--text mb-0">{{ warehouse.name }}</h4>
      <h4 class="my-0 text-uppercase">
        {{ warehouse.address.city }} -
        {{ warehouse.address.province }}
      </h4>
      <p class="mb-2">{{ warehouse.address.addressName }}</p>
      <p
        class="mb-2"
        v-if="warehouse.address && warehouse.address.referencePhoneNumber"
      >
        {{ $t("profile.contacts.homePhone") }}:
        {{ warehouse.address.referencePhoneNumber }}
      </p>
    </div>
    <v-divider class=""></v-divider>
    <div class="mt-3">
      <h5 class="font-weight-semibold primary--text mb-0">
        {{ $t("warehouse.openingTime") }}
      </h5>
      <WarehouseHours :serviceHours="warehouse.serviceHours"></WarehouseHours>
    </div>
    <v-divider class="mt-3"></v-divider>
    <!-- <div class="pb-8">
      <h5 class="font-weight-semibold primary--text mb-0">
        {{ $t("warehouse.services") }}
      </h5>
    </div> -->
  </v-container>
</template>
<style lang="scss">
.warehouse-detail {
  padding: 8px;
  .leaflet-info {
    background-color: var(--v-grey-lighten1);
    min-height: 150px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px;
    .img-wraper {
      display: flex;
      align-content: center;
      img {
        width: 110px;
        border-radius: 10px;
      }
    }
    .info-wraper {
      display: flex;
      flex-direction: column;
    }
  }
  .nav-btn {
    .v-btn__content {
      font-size: 14px;
    }
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import AddressService from "~/service/addressService";
import clickHandler from "~/mixins/clickHandler";
import WarehouseHours from "@/components/warehouse/WarehouseHours.vue";
import leafletService from "@/commons/service/leafletService";
import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";
export default {
  name: "WarehouseDetail",
  components: { WarehouseHours },
  data() {
    return {
      warehouseList: [],
      warehouse: null,
      leaflets: [],
      key: 1,
      loading: false,
      leafletStatus: false
    };
  },
  mixins: [clickHandler],
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      isAuthenticated: "cart/isAuthenticated",
      getSelectedStore: "custom/getSelectedStore"
    }),
    currentUser() {
      if (this.user && this.user.userId) {
        return this.user.userId;
      } else {
        return "noAccount";
      }
    },
    showSelectStoreBtn() {
      if (
        this.isAuthenticated &&
        parseInt(this.user.pdv) !== this.warehouse.warehouseId
      ) {
        return true;
      } else if (
        !this.isAuthenticated &&
        this.getSelectedStore !== this.warehouse.warehouseId
      ) {
        return true;
      } else return false;
    }
  },
  methods: {
    ...mapActions({
      selectStore: "custom/selectStore",
      loadCart: "cart/loadCart"
    }),
    async findWarehouse() {
      if (this.warehouseList.length == 0) {
        let data = await AddressService.findWarehouse({
          region_id: this.selectedRegionId
        });
        this.warehouseList = data.warehouses;
      }
      this.warehouse = this.warehouseList.find(
        element => element.warehouseId == this.$route.params.warehouseId
      );
      this.$route.meta.title = this.warehouse.address.city;
      var parameters = {
        parent_warehouse_id: this.warehouse.warehouseId
      };
      await leafletService.search(parameters).then(data => {
        this.leaflets = data;
        this.leafletStatus = this.setLeafletStatus();
      });
    },
    setLeafletStatus() {
      const currentDate = new Date();
      this.leaflets.forEach(leaflet => {
        const validateDateFrom = new Date(leaflet.validateDateFrom);
        const validateDateTo = new Date(leaflet.validateDateTo);

        if (currentDate >= validateDateFrom && currentDate <= validateDateTo) {
          leaflet.leafletStatus = false; //in corso
        } else if (currentDate <= validateDateTo) {
          leaflet.leafletStatus = true; //in arrivo
        } else {
          leaflet.leafletStatus = null;
        }
      });
    },
    openDetails(leafletId) {
      this.$router.push({
        name: "LeafletDetails",
        params: { leafletId: leafletId }
      });
    },
    async setStore(id) {
      if (this.isAuthenticated) {
        this.loading = true;
        await AbbondanzaRegistrationService.updatePdv(id);
        await this.loadCart();
        this.loading = false;
      } else {
        this.selectStore(id);
      }
      this.key++;
    }
  },
  mounted() {
    this.findWarehouse();
  },
  watch: {
    $route() {
      this.findWarehouse();
    }
  }
};
</script>
