<template>
  <v-list-group class="hours-list">
    <template v-slot:activator>
      <v-list-item-title
        class="d-flex  black--text today-title text-body-3"
        v-if="todayHours"
      >
        <span class="week-day font-weight-bold">{{ todayHours.weekDay }}</span>
        <v-spacer />
        <span
          class="hours"
          v-if="todayHours.hours"
          v-html="todayHours.hours"
        ></span>
      </v-list-item-title>
    </template>
    <template v-if="otherDayHours && otherDayHours.length > 0">
      <v-list-item
        v-for="day in otherDayHours"
        :key="day.weekDay"
        class="opening-day-item"
      >
        <v-list-item-title class="d-flex opening-day-title text-body-3">
          <span class="week-day">{{ day.weekDay }}</span>
          <v-spacer />
          <span class="hours" v-if="day.hours" v-html="day.hours"></span>
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-list-group>
</template>
<style lang="scss">
.hours {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    .partial-hours:not(:last-child) {
      margin-right: 4px;
      &::after {
        content: ",";
      }
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.hours-list {
  .v-list-item {
    padding: 0 3px;
    border-radius: 10px;
  }
  .week-day {
    text-transform: capitalize;
  }
}
</style>
<script>
import { calculateHourRow } from "@/warehouseHoursHelper";

export default {
  name: "WarehouseHours",
  props: {
    serviceHours: { type: Object, required: true }
  },
  data() {
    return {
      todayHours: null,
      otherDayHours: [],
      offsetDay: 1
    };
  },
  methods: {},
  mounted() {
    // day of week from server: 1 sunday, 7 saturday
    // dayJs: 0 sunday, 6 saturday
    let today = this.$dayjs();
    let todayHours = calculateHourRow(this.serviceHours, today, this.offsetDay);

    this.todayHours = {
      weekDay: today.format("dddd DD MMMM"),
      hours: todayHours ? todayHours : this.$t("warehouse.closed")
    };

    for (let i = 0; i < 6; i++) {
      let dayToCalc = this.$dayjs().add(i + 1, "day");
      let otherDay = calculateHourRow(
        this.serviceHours,
        dayToCalc,
        this.offsetDay
      );
      this.otherDayHours.push({
        weekDay: dayToCalc.format("dddd DD MMMM"),
        hours: otherDay ? otherDay : this.$t("warehouse.closed")
      });
    }
  }
};
</script>
