var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.warehouse
    ? _c(
        "v-container",
        { staticClass: "warehouse-detail" },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center justify-space-between mb-3" },
            [
              _c("h4", { staticClass: "text-uppercase my-3" }, [
                _vm._v(" " + _vm._s(_vm.$t("warehouse.activeLeaflet")) + " ")
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "nav-btn",
                  attrs: {
                    rounded: "",
                    text: "",
                    color: "primary",
                    small: "",
                    to: { name: "Leaflet" },
                    exact: ""
                  }
                },
                [
                  _vm._v(_vm._s(_vm.$t("warehouse.allShops"))),
                  _c("v-icon", { attrs: { right: "", "x-small": "" } }, [
                    _vm._v("$arrowForward")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.showSelectStoreBtn
            ? _c(
                "v-btn",
                {
                  key: "btn-n-" + _vm.key,
                  staticClass: "mt-4 mb-3 font-weight-semibold",
                  attrs: {
                    block: "",
                    "x-large": "",
                    outlined: "",
                    rounded: "",
                    loading: _vm.loading,
                    color: "secondary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setStore(_vm.warehouse.warehouseId)
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "mr-1",
                    attrs: { src: "/logo/foglia_big_t-red.png", width: "30" }
                  }),
                  _vm._v(_vm._s(_vm.$t("warehouse.markAsPrefered")))
                ]
              )
            : _c(
                "v-btn",
                {
                  key: "btn-s-" + _vm.key,
                  staticClass: "mt-4 mb-3 font-weight-semibold",
                  attrs: {
                    block: "",
                    "x-large": "",
                    outlined: "",
                    rounded: "",
                    color: "primary"
                  }
                },
                [
                  _c("img", {
                    staticClass: "mr-1",
                    attrs: { src: "/logo/foglia_big_t_filled.png", width: "30" }
                  }),
                  _vm._v(_vm._s(_vm.$t("warehouse.selectedShop")))
                ]
              ),
          _c(
            "v-row",
            { staticClass: "mt-2" },
            _vm._l(_vm.leaflets, function(leaflet, index) {
              return _c(
                "v-col",
                {
                  key: "leaflet-" + index,
                  staticClass: "pt-0",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "leaflet-info",
                      on: {
                        click: function($event) {
                          return _vm.openDetails(leaflet.leafletId)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "img-wraper" }, [
                        _c("img", {
                          attrs: {
                            src:
                              leaflet.baseLocation + leaflet.firstPagePreview,
                            alt: leaflet.name
                          }
                        })
                      ]),
                      _c("div", { staticClass: "info-wraper flex-grow-1" }, [
                        _c("h4", { staticClass: "my-0" }, [
                          _vm._v(_vm._s(leaflet.name))
                        ]),
                        _c("div", {}, [
                          _vm._v(
                            " Dal " +
                              _vm._s(
                                _vm.$dayjs(leaflet.fromDate).format("DD MMMM")
                              ) +
                              " al " +
                              _vm._s(
                                _vm.$dayjs(leaflet.toDate).format("DD MMMM")
                              ) +
                              " "
                          )
                        ]),
                        leaflet.leafletStatus == true
                          ? _c("div", { staticStyle: { color: "#FFA500" } }, [
                              _c("li", [_c("b", [_vm._v("In arrivo")])])
                            ])
                          : leaflet.leafletStatus == false
                          ? _c("div", { staticStyle: { color: "green" } }, [
                              _c("li", [_c("b", [_vm._v("In corso")])])
                            ])
                          : _vm._e()
                      ])
                    ]
                  )
                ]
              )
            }),
            1
          ),
          _c("div", { staticClass: "warehouse-detail-column mt-4" }, [
            _c("h4", { staticClass: "primary--text mb-0" }, [
              _vm._v(_vm._s(_vm.warehouse.name))
            ]),
            _c("h4", { staticClass: "my-0 text-uppercase" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.warehouse.address.city) +
                  " - " +
                  _vm._s(_vm.warehouse.address.province) +
                  " "
              )
            ]),
            _c("p", { staticClass: "mb-2" }, [
              _vm._v(_vm._s(_vm.warehouse.address.addressName))
            ]),
            _vm.warehouse.address && _vm.warehouse.address.referencePhoneNumber
              ? _c("p", { staticClass: "mb-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("profile.contacts.homePhone")) +
                      ": " +
                      _vm._s(_vm.warehouse.address.referencePhoneNumber) +
                      " "
                  )
                ])
              : _vm._e()
          ]),
          _c("v-divider", {}),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c(
                "h5",
                { staticClass: "font-weight-semibold primary--text mb-0" },
                [_vm._v(" " + _vm._s(_vm.$t("warehouse.openingTime")) + " ")]
              ),
              _c("WarehouseHours", {
                attrs: { serviceHours: _vm.warehouse.serviceHours }
              })
            ],
            1
          ),
          _c("v-divider", { staticClass: "mt-3" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }